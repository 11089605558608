<template>
  <div style="position: relative">

    <!-- CLIENT LIST -->
    <data-table
      :uri="uri"
      :uriParams="uriParams"
      :fields="fields"
      :customItems="customItems"
      :isSelectable="true"
      no-filter
    >
      <template #headerFilters>
        <b-row>
          <b-col cols="12" lg="3">
            <b-input-group prepend="Status">
              <b-form-select v-model="active" :options="statusOptions">
                <template #first>
                  <b-form-select-option :value="null">All</b-form-select-option>
                </template>
              </b-form-select>
            </b-input-group>
          </b-col>
          <b-col cols="12" lg="3">
            <b-input-group prepend="Role">
              <b-form-select v-model="role" :options="roleOptions">
                <template #first>
                  <b-form-select-option :value="null">All</b-form-select-option>
                </template>
              </b-form-select>
            </b-input-group>
          </b-col>
          <b-col cols="12" lg="3">
            <b-form-input v-model="name" placeholder="Last Name" debounce="500"></b-form-input>
          </b-col>
        </b-row>
      </template>

      <!-- ACTIONS -->
      <template #cell(action)="data">
        <b-dropdown size="sm" id="franchise-action" text="Action" class="d-print-none">
          <b-dropdown-item @click="$bvModal.msgBoxOk('Will open for editing')"><b-icon icon="pencil" aria-hidden="true"></b-icon> Edit</b-dropdown-item>
          <b-dropdown-item
            v-if="canImpersonate && data.item.id !== currentUser.id"
            @click="impersonate(data.item.username)"
          ><b-icon icon="eye" aria-hidden="true"></b-icon> Log In As</b-dropdown-item>
        </b-dropdown>
      </template>

      <!-- GROUPS -->
      <template #cell(userGroups)="data">
        <b-btn
         v-if="data.item.groups.length"
          size="sm"
          variant="link"
          title="View Groups"
          @click="showModalGroups({ name: `${data.item.profile.fname} ${data.item.profile.lname}`, groups: data.item.groups})"
          v-b-tooltip.hover
        ><b-icon-diagram3></b-icon-diagram3></b-btn>
      </template>

      <!-- FULL NAME NAME -->
      <template #cell(fullname)="data">
        <b-btn
          size="sm"
          variant="link"
          title="View Profile"
          v-b-tooltip.hover
          @click="$store.commit('SET_USER_PROFILE_ID',  data.item.id)"
        >{{ data.item.profile.fname }} {{ data.item.profile.lname }}</b-btn>
      </template>

      <!-- EMAIL -->
      <template #cell(email)="data">
        <b-btn
          v-if="data.item.email"
          size="sm"
          variant="link"
          :title="$t('label.composeAnEmail')"
          :href="'mailto:' + data.item.email"
          v-b-tooltip.hover
        >{{ data.item.email }}</b-btn>
      </template>

      <!-- ROLES -->
      <template #cell(roles)="data">
        {{ data.value.join(', ').replaceAll('ROLE_', '').replaceAll('_', ' ') }}
      </template>

      <!-- CREATED -->
      <template #cell(created)="data">
        {{ $moment(data.value).format('MM/DD/YYYY') }}
      </template>

      <!-- LAST UPDATED -->
      <template #cell(modified)="data">
        {{ $moment(data.value).format('MM/DD/YYYY') }}
      </template>
    </data-table>

    <b-row class="d-print-none">
      <b-col cols="12" lg="3">
        <!-- ACTION FILTERS -->
        <b-input-group prepend="With Selected: " class="my-3">
          <b-form-select v-model="selectedAction" :options="[
            { value: null, text: '-- Select Action --' },
            { value: 'merge', text: 'Merge' }
          ]" @change="onSelectedAction"></b-form-select>
        </b-input-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import DataTable from '@/components/DataTable'
import { constants } from '@/shared/constants'

export default {
  name: 'UserList',
  components: { DataTable },
  data () {
    return {
      roleOptions: [
        { value: 'ROLE_SUPER_ADMIN', text: this.$t('userRole.superAdmin') },
        { value: 'ROLE_ADMIN', text: this.$t('userRole.admin') },
        { value: 'ROLE_ACCOUNTING', text: this.$t('userRole.accounting') },
        { value: 'ROLE_SSC_ADMIN', text: this.$t('userRole.sscAdmin') },
        { value: 'ROLE_SSC', text: this.$t('userRole.ssc') },
        { value: 'ROLE_DIRECTOR', text: this.$t('userRole.director') },
        { value: 'ROLE_TUTOR', text: this.$t('userRole.tutor') },
        { value: 'ROLE_EC', text: this.$t('userRole.ec') },
      ], 
      statusOptions: [
        { value: true, text: 'Active' },
        { value: false, text: 'Inctive' }
      ],
      uri: constants.API_URI_USERS,
      filterLabel: 'Name / Email',
      selectedAction: null,
      customItems: {
        fullName: { key: ['profile.fname' ,'profile.lname'], label: 'Name', sortable: true /* @TODO */ }
      },
      defaultFields: [
        { key: 'action', label: '', sortable: false },
        { key: 'id', label: 'ID', sortable: true },
        { key: 'fullName', label: 'Name', sortable: true },
        { key: 'email', label: 'Email', sortable: true },
        { key: 'state', label: 'State', sortable: true },
        { key: 'roles', label: 'Roles', sortable: true },
      ],
      active: true,
      name: null,
      role: null
    }
  },
  computed: {
    uriParams () {
      const params = {
        active: this.active,
        'profile.lname': this.name,
        roles: this.role
      }
      return params
    },
    fields () {
      const fields = this.defaultFields
      return fields
    }
  },
  methods: {
    ...mapActions([
      'showModalGroups',
      'impersonate'
    ]),
    onSelectedAction (action) {
      if (action) this.$bvModal.msgBoxOk('The selected action will be taken').then(ok => {
        if (ok) this.selectedAction = null
      })
    },
  }
}
</script>
